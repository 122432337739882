<template>
  <el-card shadow="never">
    <div slot="header">
      <span class="iconfont icon-ApkUpgrade">&nbsp;APK升级</span>
    </div>
    <el-form :model="form" ref="form" label-width="100px" size="small" style="width:600px">
      <el-form-item label="升级开关">
        <el-radio-group v-model="form.iptv_apk_update_on_off" size="mini">
          <el-radio-button label="1">开启</el-radio-button>
          <el-radio-button label="0">关闭</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="强制升级">
        <el-radio-group v-model="form.iptv_apk_force_update" size="mini">
          <el-radio-button label="1">是</el-radio-button>
          <el-radio-button label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="IPTV版本号">
        <el-input v-model="form.iptv_apk_version"></el-input>
      </el-form-item>
      <el-form-item label="内部版本号">
        <el-input v-model="form.iptv_apk_version_code"></el-input>
      </el-form-item>
      <el-form-item label="更新介绍">
        <el-input type="textarea" v-model="form.iptv_apk_update_introduction"  :rows="6"></el-input>
      </el-form-item>
       <!-- <el-form-item label="APK地址:" v-if="form.iptv_apk_url">
        {{form.iptv_apk_url}}
      </el-form-item> -->
      <el-form-item label="上传应用">
        <el-upload
          ref="apk-upload"
          class="upload-demo"
          drag
          action="/api/system/apk_update/upload_file_app"
          accept=".apk"
          :on-success="handleSuccess"
          :before-upload="handleBefore"
          :file-list="fileList"
          :headers="headers"
          :show-file-list="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过50M</div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi,editApkVersionApi } from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      //上传头部POST请求头部带token信息验证登录状态
      headers: {
        accessToken: this.$store.state.user.authorization
      },
      form: {},
      fileList: []
    };
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getSystemConfigApi({ type: "iptv_app_update" }).then(res => {
        this.form = res.data;
      });
    },
    //上传前验证判断
    handleBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      this.$message({
        type: res.type,
        message: res.msg
      });
      this.initLoad();
      this.$refs["apk-upload"].clearFiles();
    },
    //保存
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res)
          this.$message({
            type: res.type,
            message: res.msg
          });
      });
      //添加版本记录
      editApkVersionApi(this.form);
    }
  }
};
</script>
<style lang='scss'>
</style>